import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import names from "../assets/data/names"
import { useGalleryContext } from "../context/Context"
import ModalPicture from "./ModalPicture"

let len,
  imgRibbon,
  back,
  forward,
  container,
  interval,
  distance,
  indexSlideShow = 0,
  // swipe
  touchStartX,
  touchMoveX,
  touchEndX,
  lengthOfSwipe

const Slider = ({ data }) => {
  const {
    showModal,
    controlShowArrows,
    fadeArrow,
    showArrow,
    modalToggle,
    index,
    setIndex,
    addEvent,
    arrayFromQuery,
    showArrows,
    toggleCheckScroll,
    setToggleCheckScroll,
  } = useGalleryContext()

  useEffect(() => {
    len = 0
    setIndex(0)
    distance = document.querySelector(".slider-container").offsetWidth - 0.1
    imgRibbon = Array.from(document.querySelectorAll(".slider-container__img"))
    back = document.querySelector(".back")
    forward = document.querySelector(".forward")
    container = document.querySelector(".slider-container")
    window.addEventListener("resize", e => {})
    addEvent("touchstart", container, touchStart)
    addEvent("touchmove", container, touchMove)

    // slideshow
    document.addEventListener("mousedown", () => clearInterval(interval))
    document.addEventListener("keydown", () => clearInterval(interval))
    document.addEventListener("touchstart", () => clearInterval(interval))
    slideShow(6000)

    // fix for modal
    setToggleCheckScroll("go")
  }, [])

  useEffect(() => navigate("/"), [toggleCheckScroll])

  // hotkeys
  const hotkeys = e => {
    const key = e.key
    if (key === "ArrowLeft") toggleSlider("back")
    if (key === "ArrowRight") toggleSlider("forward")
  }

  useEffect(() => addEvent("keydown", document, hotkeys), [index])

  useEffect(() => addEvent("touchend", container, touchEnd), [index])
  useEffect(() => showArrows(back, forward, imgRibbon), [index])

  // slider
  const toggleSlider = action => {
    len = -index * distance
    if (action === "back") {
      if (index === 0) return

      imgRibbon[index].style.opacity = "0"
      imgRibbon[index - 1].style.opacity = "1"
      setIndex(index - 1)
      len += distance
    }

    if (action === "forward") {
      if (index === imgRibbon.length - 1) return
      let newIndex = index
      setIndex(newIndex + 1)
      imgRibbon[newIndex + 1].style.opacity = "1"
      len -= distance
    }
    controlShowArrows(action, back, forward, imgRibbon)
    moveSlider(len)
    setTimeout(giveShadow.bind(this, action, index), 400)
  }

  const giveShadow = (action, index) => {
    if (action === "back")
      container.style.boxShadow = `0px 13px 13px -10px ${
        names.shadows[index - 1]
      }`
    else
      container.style.boxShadow = `0px 13px 13px -10px ${
        names.shadows[index + 1]
      }`
  }

  const moveSlider = len => {
    imgRibbon.forEach(i => {
      i.style.transform = `translateX(${len}px)`
    })
  }

  // slideShow
  const slideShow = speed => {
    clearInterval(interval)
    interval = setInterval(toggleSlideShow, speed)
  }

  const toggleSlideShow = () => {
    if (indexSlideShow < imgRibbon.length - 1) {
      const distance = document.querySelector(".slider-container").offsetWidth
      len -= distance
      moveSlider(len)
      indexSlideShow += 1
      setIndex(indexSlideShow)
      setTimeout(giveShadow.bind(this, "forward", indexSlideShow), 400)
      imgRibbon[indexSlideShow].style.opacity = "1"
      if (index === 0) showArrow(back)
      if (indexSlideShow === imgRibbon.length - 1) fadeArrow(forward)
    }
  }
  // swipe
  const handleGesture = () => {
    lengthOfSwipe = touchStartX - touchMoveX

    if (Math.abs(lengthOfSwipe) < 100) {
      imgRibbon.forEach(i => {
        i.style.transform = `translateX(${len - lengthOfSwipe}px)`
      })
    }
    if (lengthOfSwipe > 0) imgRibbon[index + 1].style.opacity = "1"
  }

  const handleSwipe = () => {
    // trying to expand conditions
    lengthOfSwipe = touchStartX - touchEndX
    if (lengthOfSwipe > 75) {
      toggleSlider("forward")
    }
    if (lengthOfSwipe < -75) {
      toggleSlider("back")
    } else moveSlider(len)
  }

  const touchStart = e => {
    document.body.style.overflowY = "hidden"
    touchStartX = e.changedTouches[0].clientX
  }

  const touchMove = e => {
    touchMoveX = e.changedTouches[0].clientX
    handleGesture()
  }

  const touchEnd = e => {
    touchEndX = e.changedTouches[0].clientX
    handleSwipe()
    document.body.style.overflowY = "scroll"
  }

  return (
    <section className="slider">
      {modalToggle && (
        <div className="modal">
          <ModalPicture list={imgRibbon} period="2000" />
        </div>
      )}
      <div className="slider-container">
        <div className="slider-subcontainer">
          {arrayFromQuery(data).map(picture => {
            const { name: id } = picture
            const image = getImage(picture)
            return (
              <div className="slider-container__img" id={`image-${id}`}>
                <GatsbyImage
                  image={image}
                  key={id}
                  alt="full screen picture"
                  data-index={id}
                  imgClassName={`img-${id}`}
                  className={"slider-img"}
                  onClick={e => showModal(e)}
                  imgStyle={{ objectFit: `contain`, objectPosition: `bottom` }}
                />
              </div>
            )
          })}
        </div>
        <div className="wall"></div>
      </div>
      <div className="slider-controls">
        <button
          className="slider-button__left back"
          onClick={() => toggleSlider("back")}
        >
          <span className="slider-button__left-line backward"></span>
          <span className="slider-button__left-arrow backward"></span>
        </button>
        <div className="slider-desc">
          <p>{names["2000"][index]}</p>
        </div>
        <button
          className="slider-button__right  forward"
          onClick={() => toggleSlider("forward")}
        >
          <span className="slider-button__right-line"></span>
          <span className="slider-button__right-arrow"></span>
        </button>
      </div>
    </section>
  )
}
export default Slider

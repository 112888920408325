import React from "react"
import Slider from "../components/Slider"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Exibitions from "../components/Exibitions"
const IndexPage = ({ data }) => {
  return (
    <>
      <Exibitions />
      <Seo />
      <Slider data={data} />
    </>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "2000" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            formats: WEBP
            quality: 50
          )
        }
        name
      }
    }
  }
`
export default IndexPage

import React from "react"
import Nbsp from "../assets/data/nbsp"

const Exibitions = () => {
  return (
    <div className="exibitions">
      <h2>
        <span>06.02.2023 - 17.02.2023. </span>
        «Новый сад». Выставка
        <nobr> в честь</nobr> юбилея 70 лет
      </h2>
      <p>
        Выставка пройдет в зале МСХ на Старосадском пер. д. 5, г. Москва,{" "}
        <span>
          <a
            className="link"
            href="https://yandex.ru/maps/213/moscow/house/starosadskiy_pereulok_5_8s4/Z04YcANgSEQGQFtvfXt0dn9nYA==/?ll=37.640111%2C55.757344&z=16"
            target="_blank"
            rel="noreffer"
          >
            карта
          </a>
        </span>
        <br />
        Открытие состоится 06.02 в 16:00
      </p>
    </div>
  )
}

export default Exibitions
